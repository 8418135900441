// Colors
$brand-color: #ffbe24;
$background-color: #f4f4f4;
$adding-brand-color: #ffcd55;
$brand-dark-color: #293036;
$brand-navy: #0f113a;
$brand-gray: #6c7691;
$brand-tan: #f7f5f2;
$white: #fff;
$black: #000;
$gray: #dddddd;
$danger-brand: #b84e4e;
$text-dark-color: #747474;
$text-color-heading: #43415f;
$text-color: #717171;
$text-brand: darken($brand-color, 10%);
$text-gray: #717171;
$text-yellow: #ffbe24;
$link-color: #3b86ff;
$bkg-base: #e9faf8;
$bkg-gray: #f6f6f6;
$bkg-gray-light: #fafafa;
$green: #287f4d;
$active-color: #306dd0;
$credit-card-border: #dee2e6;
$point-color: #d4d4d4;
$checkbox-border: #ced4da;
$chips: #dff1e7;

$title-color: #666666;
$scroll-bar-color: #555555;

$danger: $danger-brand;
$info: #306dd0;
$success: #287f4d;
$warn: #ffbe24;

//btn
$btn-border-radius: 3px;
$btn-border: none;
$btn-box-shadow: none;
$padding-btn: 10px 25px;
$button-text-color: $brand-navy;

//success
$btn-success-color: $brand-color;
$btn-success-hover-color: $adding-brand-color;

//light
$btn-light-color: $brand-gray;
$btn-light-hover-color: #929ab0;
$btn-gray: lighten($brand-gray, 20%);

// Typography
$font-family-base: "sourcesanpro";
$font-family-heading: "cabin";
$font-family-thin: "cabinregular";
$font-family-os: "Open Sans", sans-serif;
$font-size-base: 0.9rem;
//== Components



//sidebar

$sidebar-background: $brand-color;

//dropdown
$sidebar-dropdown-background: darken($bkg-gray, 3%);
$sidebar-dropdown-color: darken($text-color, 10%);


//paginator

$pagination-button-size: 40px;
$pagination-active-color: $brand-color;
$pagination-position-size: 10px 5px;
$pagination-border-radius: 4px;

$background-image: url(/images/background_log.png);
$logo-image: url(/assets/images/logo.png);
$white-logo-image: url(/assets/images/white_logo.png);

//registration
$background-content: $bkg-gray-light;

