/* You can add global styles to this file, and also import other style files */
@import 'override';
@import 'fonts';
@import 'app';
@import 'media';
@import 'util';
@import 'print';
@import 'override_bootstrap';

@import 'bootstrap/scss/bootstrap';
@import '~font-awesome/css/font-awesome.css';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/nova-light/theme.css';
@import '~primeng/resources/primeng.min.css';
@import '~vanilla-cookieconsent/dist/cookieconsent.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import './assets/style/variables/_cookieconsent.scss';

html,
body {
  font-family: $font-family-base;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}

.text-transform-none {
  text-transform: none !important;
}

.loader {
  display: block;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

ol,
ul {
  list-style: none;
}

table {
  img {
    max-width: 140px;
  }
  font-weight: 300;

  tr {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
    }
    td:last-child {
      overflow: inherit;
    }
  }
}

.brand-table-background {
  tr {
    border-bottom: 3px solid $bkg-gray;
    th {
      background: $bkg-gray;
      border: none;
      color: $title-color;
    }
    td {
      background: $white;
    }
  }
}

.sidebar-wrapp {
  float: left;
  padding: 0;
}

.full-height {
  min-height: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: initial;
  height: 100%;
  overflow: hidden;
}

.content-title {
  margin: 1em 0 0 0;
  color: $title-color;
  font-weight: 300;
  font-family: $font-family-heading;
}

.content-description {
  margin: 1em 0 0 0;
  color: $text-color;
  font-weight: 300;
}

.main-content {
  height: 100%;
}

.content-wrapp {
  height: 100%;
  width: 100%;
  padding: 2em 7%;
  margin: 0 auto;
  background: $bkg-gray;
  overflow-y: scroll;
  .nav-tabs {
    padding-top: 1em;
    .nav-link {
      text-transform: capitalize;
    }
    border-bottom: 1px solid $gray;
    .nav-link.active,
    .nav-link:focus {
      color: $link-color;
      border-bottom: 2px solid $link-color;
      background: $bkg-gray;
    }
    .nav-item {
      a {
        border: none;
        color: $text-gray;
        font-size: 1.1rem;
        font-weight: 300;
        cursor: pointer;
      }
    }
    .nav-link:hover {
      color: $link-color;
      border-bottom: 2px solid $link-color;
    }
  }
}

input {
  padding: 4px;
  box-shadow: none;
}
input:hover,
input:focus {
  box-shadow: none;
  outline: none;
}
input::-webkit-input-placeholder {
  color: $gray;
}
input::-moz-placeholder {
  color: $gray;
} /* Firefox 19+ */
input:-moz-placeholder {
  color: $gray;
} /* Firefox 18- */
input:-ms-input-placeholder {
  color: $gray;
}

input {
  padding: 4px;
  box-shadow: none;
}

input:hover,
input:focus {
  box-shadow: none;
  outline: none;
}

input::-webkit-input-placeholder {
  color: $gray;
}

input::-moz-placeholder {
  color: $gray;
}

/* Firefox 19+ */
input:-moz-placeholder {
  color: $gray;
}

/* Firefox 18- */
input:-ms-input-placeholder {
  color: $gray;
}

.thick-brand-font {
  font-weight: 300;
  color: $title-color;
}

.title-color {
  color: $title-color;
}

app-service-logo {
  vertical-align: middle;
  display: inline-block;
}

.filter-title {
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: $title-color;
}

body .full-width .btn-gray-left {
  background: $btn-gray;
  position: absolute;
  right: 0;
  padding: 6px 25px;
  border-radius: unset;
}

.btn-gray {
  background: $btn-gray;
}

.light-text {
  font-size: 0.7rem;
  line-height: 1.1;
  color: $text-color;
}
.bold-text {
  font-weight: 600;
}

body .full-width .btn-yellow-left {
    font-size: 1.4rem;
    padding: 3px 25px;
    background: var(--main_button_color);
    color: var(--main_button_text_color);
    border-radius: unset;
}

body button:disabled {
  cursor: not-allowed;
}

.title-case {
  text-transform: capitalize;
}

.tooltip-list {
  padding-left: 15px;
  list-style: disc;
  column-count: 2;
}

.ui-tooltip.tooltip-with-list {
  max-width: 550px;
}

@media (max-width: 900px) {
  .ui-tooltip.tooltip-with-list {
    max-width: 60vw;
  }

  .tooltip-list {
    column-count: 1;
  }
}

.highchart-container {
  min-height: 330px;

  &--subscriptions {
    height: 220px;
  }
}

.custom-title {
  margin-bottom: 0.5rem;
  margin-top: 0;
  line-height: 1.2;

  &-h1 {
    font-size: 2.25rem;
  }

  &-h2 {
    font-size: 1.8rem;
  }

  &-h3 {
    font-size: 1.575rem;
  }

  &-h4 {
    font-size: 1.35rem;
  }

  &-h5 {
    font-size: 1.125rem;
  }

  &-h6 {
    font-size: 1rem;
  }
}

iframe {
  border: 0;
  width: 100%;
  height: 500px;
}

.form-error {
  display: block;
  color: $danger-brand;
  font-size: 12px;
}
