/* Cabin */
@font-face {
  font-family: 'cabin';
  src: url('../../../assets/fonts/Cabin-SemiBold.eot');
  src: url('../../../assets/fonts/Cabin-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../../../assets/fonts/Cabin-SemiBold.woff') format('woff'),
  url('../../../assets/fonts/Cabin-SemiBold.ttf') format('truetype'),
  url('../../../assets/fonts/Cabin-SemiBold.svg#cabin') format('svg');
}

@font-face {
  font-family: 'cabinregular';
  src: url('../../../assets/fonts/Cabin-Regular.eot');
  src: url('../../../assets/fonts/Cabin-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../../assets/fonts/Cabin-Regular.woff') format('woff'),
  url('../../../assets/fonts/Cabin-Regular.ttf') format('truetype'),
  url('../../../assets/fonts/Cabin-Regular.svg#cabinRegular') format('svg');
}

/* Source Sans Pro */
@font-face {
  font-family: 'sourcesanpro';
  src: url('../../../assets/fonts/SourceSansPro-Regular.eot');
  src: url('../../../assets/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../../assets/fonts/SourceSansPro-Regular.woff') format('woff'),
  url('../../../assets/fonts/SourceSansPro-Regular.ttf') format('truetype'),
  url('../../../assets/fonts/SourceSansPro-Regular.svg#sourcesanpro') format('svg');
}

/* Icons fonts */
@font-face {
  font-family: 'paydock';
  src: url('../../../assets/fonts/paydock.eot?14730701');
  src: url('../../../assets/fonts/paydock.eot?14730701#iefix') format('embedded-opentype'),
  url('../../../assets/fonts/paydock.woff2?14730701') format('woff2'),
  url('../../../assets/fonts/paydock.woff?147307') format('woff'),
  url('../../../assets/fonts/paydock.ttf?14730701') format('truetype'),
  url('../../../assets/fonts/paydock.svg?14730701#paydock') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxy';
  src: url('../../../assets/fonts/proxy.eot?14730701');
  src: url('../../../assets/fonts/proxy.eot?14730701#iefix') format('embedded-opentype'),
  url('../../../assets/fonts/proxy.woff2?14730701') format('woff2'),
  url('../../../assets/fonts/proxy.woff?147307') format('woff'),
  url('../../../assets/fonts/proxy.ttf?14730701') format('truetype'),
  url('../../../assets/fonts/proxy.svg?14730701#proxy') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'screen-alt';
  src: url('../../../assets/fonts/screen-alt.eot?61598924');
  src: url('../../../assets/fonts/screen-alt.eot?61598924#iefix') format('embedded-opentype'),
       url('../../../assets/fonts/screen-alt.woff?61598924') format('woff'),
       url('../../../assets/fonts/screen-alt.woff2?61598924') format('woff2'),
       url('../../../assets/fonts/screen-alt.ttf?61598924') format('truetype'),
       url('../../../assets/fonts/screen-alt.svg?61598924#screen-alt') format('svg');
  font-weight: normal;
  font-style: normal;
}
