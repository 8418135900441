@import "brand";

@media only screen and (max-width: 991px) {
  .menu {
    left: 200px;
  }

  .sidebar-wrapp {
    position: absolute !important;
    z-index: 300;
    height: 100%;
    width: 0;
  }
}

@media only screen and (max-width: 768px) {
  html {
    height: 100vh;
  }

  body {

    .customer-list-wrap {
      .customer-item {
        width: 100% !important;
        flex: 100% !important;
      }
    }

    .customer-subscriptions-wpap .customer-subscriptions,
    .customer-payments-wpap .customer-payments {
      display: table-row !important;
    }

    .choose-payment-source .new, .choose-payment-source .exist,
    .choose-payment-source .new .details-wrap .details,
    .choose-payment-source .exist .details-wrap .details {
      min-width: unset !important;
    }

    .mobile-button {
      width: 100%;
      margin-bottom: 10px;
    }

    .create-cp-box {
      padding: .5em !important;
    }

    .mobile-hide {
      display: none;
    }

    .registration-background {
      display: none;
    }

    .registration-content {
      width: 100%;
      max-width: unset;
      overflow: auto;
    }

    .content-wrapp {
      margin: 0;
      max-width: unset;
      flex: 0 0 100%;
    }
  }

  .filter-title {
    display: none;
  }

  .wrapp-select {
    margin-bottom: 20px;
  }
  .wrapp-chips {
    min-height: 36px;
  }

}

