%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.d-grid {
  display: grid;

  &-autofit {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
}
