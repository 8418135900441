.cc--neutral {
  color-scheme: light;

  /* -------------------------------------------------------------------------- */
  /*                                   tokens                                   */
  /* -------------------------------------------------------------------------- */

  $black: #000000;
  $grey: #5e6266;
  $slate: #667481;
  $silver-dark: #d4dae0;
  $silver: #d5dee2;
  $silver-light: #dee4e9;
  $smoke: #eaeff2;
  $ghost: #f0f4f7;
  $white: #ffffff;
  $success: #5eba85;
  $primary: $black;
  $secondary: $grey;

  /* -------------------------------------------------------------------------- */
  /*                                   buttons                                  */
  /* -------------------------------------------------------------------------- */

  --cc-btn-primary-bg: #{$white};
  --cc-btn-primary-color: #{$black};
  --cc-btn-primary-border-color: #{$black};
  --cc-btn-primary-hover-bg: #{$black};
  --cc-btn-primary-hover-color: #{$white};
  --cc-btn-primary-hover-border-color: #{$black};

  --cc-btn-secondary-bg: #{$black};
  --cc-btn-secondary-color: #{$white};
  --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
  --cc-btn-secondary-hover-bg: #{$secondary};
  --cc-btn-secondary-hover-color: #{$white};
  --cc-btn-secondary-hover-border-color: #{$silver-dark};

  --cc-separator-border-color: #{$ghost};

  /* -------------------------------------------------------------------------- */
  /*                                  toggles                                   */
  /* -------------------------------------------------------------------------- */

  --cc-bg-primary-color: #{$white};

  --cc-toggle-on-bg: #{$success};
  --cc-toggle-on-knob-bg: #{$white};

  --cc-toggle-off-bg: #{$slate};
  --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);

  --cc-toggle-enabled-icon-color: var(--cc-bg-primary-color); // yes (v tick)
  --cc-toggle-disabled-icon-color: var(--cc-bg-primary-color); // no (x tick)

  --cc-toggle-readonly-bg: #{$success};
  --cc-toggle-readonly-knob-bg: #{$white};
  --cc-toggle-readonly-knob-icon-color: #{$silver};

  /* -------------------------------------------------------------------------- */
  /*                                 categories                                 */
  /* -------------------------------------------------------------------------- */

  --cc-cookie-category-block-bg: #{$ghost};
  --cc-cookie-category-block-border: #{$ghost};
  --cc-cookie-category-block-hover-bg: #{$smoke};
  --cc-cookie-category-block-hover-border: #{$smoke};
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: #{$silver-light};

  --cc-section-category-border: var(--cc-cookie-category-block-bg);

  /* -------------------------------------------------------------------------- */
  /*                                   footer                                   */
  /* -------------------------------------------------------------------------- */

  --cc-footer-bg: #{$smoke};
  --cc-footer-color: #{$secondary};
  --cc-footer-border-color: #{$ghost};

  /* -------------------------------------------------------------------------- */
  /*                                miscellaneous                               */
  /* -------------------------------------------------------------------------- */

  --cc-overlay-bg: #{rgba($black, 0.65)};
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);

  /* -------------------------------------------------------------------------- */
  /*                                  overrides                                 */
  /* -------------------------------------------------------------------------- */

  #cc-main {
    .pm__badge {
      background-color: #{$white};
      padding-inline: 0.5rem;
      padding-block: 0.125rem;
    }

    .pm__close-btn {
      background-color: #{$ghost};
      border: 0px;

      svg {
        stroke: #{$secondary};
      }

      &:hover {
        background-color: #{$silver};
        svg {
          stroke: #{$primary};
        }
      }
    }

    .pm__section-title-wrapper {
      .pm__section-arrow {
        color: #{$black};
        background-color: #{$silver-dark};

        svg {
          stroke: #{$black};
        }
      }

      .section__toggle-wrapper {
        top: 1.125rem;
        right: 1.125rem;
        margin: 0;

        .section__toggle {
          &:checked {
            &:disabled ~ .toggle__icon {
              background: #{$silver};
              background: #{rgba($success, 0.5)};
              box-shadow: 0 0 0 1px #{$silver};
              box-shadow: 0 0 0 1px #{rgba($success, 0.5)};
            }
          }
        }
      }
    }
  }
}
