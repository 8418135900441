@import 'brand';

body {
  .full-width {
    .btn {
      border: $btn-border;
      box-shadow: none;
      border-radius: $btn-border-radius;
      padding: $padding-btn;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    a.btn:not([href]):not([tabindex]) {
      color: $white;
    }

    .btn:hover {
      border: $btn-border;
      box-shadow: $btn-box-shadow;
      opacity: 0.7;
    }

    .btn-success:disabled {
      cursor: not-allowed;
    }

    .btn-success.focus,
    btn-success:not(:disabled):not(.disable):focus {
      box-shadow: none;
    }

    .btn-download {
      border-radius: unset;
      i {
        margin-right: 8px;
      }
    }


    /* override checkbox for prime ng */
    .ui-chkbox .ui-chkbox-box,
    .ui-chkbox .ui-chkbox-box:not( :hover ) {
      border-color: $checkbox-border;
    }
    .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover {
      border-color: $checkbox-border;
    }

    .ui-chkbox .ui-chkbox-box.ui-state-active,
    .ui-chkbox .ui-chkbox-box.ui-state-active:not( :hover ),
    .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover,
    .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
      border-color: $checkbox-border;
      background-color: $white;
      color: var(--main_button_color);
    }
  }

  .table-hover tbody tr:hover {
    background: $white;
  }

  .alert {
      border: none;
  }
}

.ui-calendar {
  display: block;
}

//modal
body {
  .ui-widget {
    font-family: $font-family-base;
    p-header {
      font-size: 20px;
    }
  }

  .ui-dialog {
    z-index: 100;
    overflow: auto;
    max-height: 80%;

    .ui-dialog-titlebar {
      padding: 1em 2em;
      background: $white;
      border: none;
      text-transform: capitalize;
    }

    .ui-dialog-content {
      border: none;
      padding: 0 2em;
    }
    .ui-dialog-footer {
      padding: 1em 0 0 0;
      border: none;
      button, a {
        margin: 0;
        width: 50%;
        border-radius: 0;
      }
    }
  }
  .text-active {
      color: $active-color
  }
}

/* scroll */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid $scroll-bar-color;
  background-color: $scroll-bar-color;
}


select.form-control {
  padding: 0.375rem 0.2rem;
  option {
    font-size: 0.8rem;
  }
}

.text-yellow {
  color: $text-yellow;
}

.text-info {
  color: $text-gray !important;
}

.text-active {
  color: #306dd0 !important;
}
