:root {
  --lower_link_color: #4f4f4f;
  --logo_underline_color: #4f4f4f;
  --sidebar_logo_background_color: #{lighten(#666666, 50%)};
  --link_color: #2C7778;
  --navigation_link_color: #4f4f4f;
  --navigation_hover_color: #000000;
  --navigation_hover_background: transparent;
  --main_button_color: #000000;
  --main_button_text_color: #ffffff;
}

a, a:hover {
  color: var(--link_color);
}

.btn-link:hover {
  color: $white;
  text-decoration: none;
}

a:hover {
  opacity: .7;
}

list-actions a:hover {
  opacity: 1;
}

.title-color {
  color: $title-color;
}

.text-yellow {
  color: $text-yellow;
}

.text-info {
  color: $text-gray !important;
}

.text-active {
  color: #306dd0 !important;
}
